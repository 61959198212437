<template>
    <lassoLayout class="page-music-release-submitted">
        <div class="p-music-release-submitted">
            <div class="content-page">
                <div class="block_music_release_submitted">
                    <h2>
	                    Success!
                    </h2>
                    <div class="description">
	                    Your release has been submitted. Please allow some time for review.
	                    If you have any questions visit our <a href="https://discord.gg/scABv4gpgw" target="_blank">discord channel</a>
                    </div>
                    <div class="illustration congratul"></div>

                    <div class="action_buttons">
                        <router-link :to="{name: 'explore'}" class="tetriatary_btn medium">Home</router-link>
                        <router-link :to="{name: 'uploads'}" class="primary_btn medium">Releases</router-link>
                    </div>
                </div>
            </div>
        </div>
    </lassoLayout>
</template>

<script>
import lassoLayout from "@/layouts/lassoLayout.vue";
import confetti from '@/mixins/confetti.js';
export default {
    name: "musicReleaseSubmitted",
    components: {
        lassoLayout
    },
	mixins: [confetti]
}
</script>

<style lang="scss">


</style>